<template>
  <div class="parent-info">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns">
        <div class="column is-10 is-offset-1">
          <b-tabs v-model="activeTab">
            <b-tab-item label="Parent">
              <div
                class="columns"
                v-for="item in Object.entries(parent)"
                :key="item.id"
              >
                <template v-if="!excludedKeys.includes(item[0])">
                  <div class="column is-3 primary">{{ item[0] }}</div>
                  <div class="column is-9 text-bg-color">
                    {{ itemValue(item[1]) }}
                  </div>
                </template>
              </div>
              <div class="buttons is-pulled-right">
                <a
                  class="is-small has-text-success"
                  @click="initializeUpdate()"
                  style="font-size: 1.2em; margin-left: 16px"
                >
                  <fa-icon icon="edit" size="lg" />
                </a>
                <a
                  class="is-small has-text-danger"
                  @click="initializeRemove()"
                  style="font-size: 1.2em; margin-left: 16px"
                >
                  <fa-icon icon="trash-alt" size="lg" />
                </a>
              </div>
            </b-tab-item>
            <b-tab-item label="Students">
              <div style="min-height: 300px" class="mx-0">
                <b-table :data="students" hoverable>
                  <b-table-column
                    field="#"
                    label="#"
                    width="40"
                    numeric
                    v-slot="props"
                  >
                    {{ students.indexOf(props.row) + 1 }}
                  </b-table-column>

                  <b-table-column label="Image" v-slot="props">
                    <figure class="image is-48x48">
                      <img
                        class="is-rounded"
                        :src="props.row.imageUrl"
                        alt="avatar"
                        v-if="props.row.imageUrl"
                      />
                      <img
                        class="is-rounded"
                        src="@/assets/images/default_avatar.png"
                        alt="avatar"
                        v-else
                      />
                    </figure>
                  </b-table-column>

                  <b-table-column label="Name" v-slot="props">
                    {{ props.row.name }}
                  </b-table-column>

                  <b-table-column label="School Class" v-slot="props">
                    {{ props.row.schoolClass.name }}
                  </b-table-column>

                  <b-table-column label="Status" v-slot="props">
                    {{ props.row.status }}
                  </b-table-column>

                  <b-table-column label="Actions" width="160" v-slot="props">
                    <p class="buttons has-text-right">
                      <a
                        class="is-small has-text-danger"
                        @click="initializeRemoveStudent(props.row)"
                        style="font-size: 1.1em; margin-left: 16px"
                      >
                        <fa-icon icon="trash-alt" size="lg" />
                      </a>
                    </p>
                  </b-table-column>
                </b-table>
              </div>
            </b-tab-item>
            <b-tab-item
              label="Online"
              v-if="user.role === 'host' || user.role === 'admin'"
            >
              <div class="columns is-multiline">
                <div class="column is-12">
                  <p class="subtitle is-6">
                    Enable to allow online access for parents
                  </p>
                  <template
                    v-if="parent.emailOfFather !== parent.emailOfMother"
                  >
                    <div class="field">
                      <b-switch v-model="fatherAuth" type="is-success"
                        >Father Auth</b-switch
                      >
                    </div>
                    <div class="field">
                      <b-switch v-model="motherAuth" type="is-success"
                        >Mother Auth</b-switch
                      >
                    </div>
                  </template>
                  <template v-else>
                    <div class="field">
                      <b-switch v-model="userAuth" type="is-success"
                        >Parents Auth</b-switch
                      >
                    </div>
                  </template>
                </div>
              </div>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </template>
    <!-- Delete -->
    <app-modal
      id="remove-activate-reset-parent"
      :context="context"
      @remove="remove"
      @activate="activate"
      @reset="reset"
      :show-modal.sync="isModalOpen"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
const randomstring = require('randomstring')
import { crudNotification } from '@/assets/js/notification'
import { fetchUser } from '@/assets/js/app_info'

export default {
  data() {
    return {
      schoolId: null,
      user: {},
      userId: null,
      pageTitle: 'Parent Info',
      parent: {
        id: null,
        country: {
          name: 'Nigeria',
        },
        state: {
          name: null,
        },
        stateLga: {
          name: null,
        },
        familyName: '',
        nameOfFather: '',
        dobOfFather: '',
        phoneOfFather: '',
        emailOfFather: '',
        nameOfMother: '',
        dobOfMother: '',
        phoneOfMother: '',
        emailOfMother: '',
        houseAddress: null,
        users: null,
      },
      students: [],
      excludedKeys: ['id', 'users', '__typename'],
      activeTab: 0,
      context: null,
      removeContext: 'Parent',
      fatherAuth: false,
      motherAuth: false,
    }
  },
  watch: {
    activeTab(tab) {
      if (tab == 1) {
        this.$apollo.addSmartQuery('students', {
          query: gql`
            query ParentStudentsQuery($parentId: ID!) {
              studentsForParent(parentId: $parentId) {
                id
                imageUrl
                name
                schoolClass {
                  name
                }
                status
              }
            }
          `,
          variables: {
            parentId: parseInt(this.$route.params.id),
          },
          update: (data) => data.studentsForParent,
        })
      }
    },
    parent(data) {
      if (data.emailOfFather === data.emailOfMother) {
        this.selectedUser = data.users[0]
        this.userAuth = this.isActivated()
      } else {
        this.fatherAuth = this.isFatherActivated()
        this.motherAuth = this.isMotherActivated()
      }
    },
    isModalOpen() {
      this.$apollo.queries.parent.refetch()
      if (this.parent.emailOfFather === this.parent.emailOfMother) {
        this.userAuth = this.isActivated()
      } else {
        this.fatherAuth = this.isFatherActivated()
        this.motherAuth = this.isMotherActivated()
      }
    },
    fatherAuth(value) {
      this.userId = this.fatherUser.id
      if (value && !this.isFatherActivated()) {
        this.context = 'activate'
        this.openModal()
      } else if (!value && this.isFatherActivated()) {
        this.context = 'reset'
        this.openModal()
      }
    },
    motherAuth(value) {
      this.userId = this.motherUser.id
      if (value && !this.isMotherActivated()) {
        this.context = 'activate'
        this.openModal()
      } else if (!value && this.isMotherActivated()) {
        this.context = 'reset'
        this.openModal()
      }
    },
  },
  computed: {
    emailOfFather() {
      return this.parent['emailOfFather'] ? this.parent['emailOfFather'] : ''
    },
    emailOfMother() {
      return this.parent['emailOfMother'] ? this.parent['emailOfMother'] : ''
    },
    fatherUser() {
      const [user] = this.parent.users.filter(
        (user) => user.email === this.emailOfFather
      )
      return user
    },
    motherUser() {
      const [user] = this.parent.users.filter(
        (user) => user.email === this.emailOfMother
      )
      return user
    },
  },
  methods: {
    isFatherActivated() {
      return this.fatherUser.firebaseUserId.length > 10
    },
    isMotherActivated() {
      return this.motherUser.firebaseUserId.length > 10
    },
    initializeUpdate() {
      this.$router.push(
        `/school/${this.schoolId}/parent/${this.$route.params.id}`
      )
    },
    itemValue(value) {
      if (!value) return
      if (typeof value === 'object') {
        const { name } = value
        value = name
      }
      return value
    },
    initializeRemoveStudent(student) {
      this.context = 'remove'
      this.student = student
      this.openModal()
    },
    initializeRemove() {
      this.context = 'remove'
      this.openModal()
    },
    remove() {
      if (this.activeTab === 0) {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation deleteParentQuery($id: Int!) {
                deleteParent(input: { id: $id }) {
                  parent {
                    id
                  }
                  errors
                }
              }
            `,
            variables: {
              id: parseInt(this.parent.id),
            },
          })
          .then((response) => {
            crudNotification(
              response.data.deleteParent.errors,
              'Successfully deleted.'
            ).then(() => {
              this.closeModal()
              this.$apollo.queries.parent.refetch()
            })
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation RemoveParent($id: Int!) {
                removeParent(input: { id: $id }) {
                  student {
                    id
                  }
                  errors
                }
              }
            `,
            variables: {
              id: parseInt(this.student.id),
            },
          })
          .then((response) => {
            crudNotification(
              response.data.removeParent.errors,
              'Successfully removed.'
            ).then(() => {
              this.closeModal()
              this.$apollo.queries.students.refetch()
            })
          })
          .catch((error) => {
            // console.error(error);
          })
      }
    },
  },
  mounted() {
    this.skipQuery = false
    this.$store.commit('setPageTitle', this.pageTitle)
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setSubMenus', [
      {
        name: 'Parents',
        route: `/school/${this.schoolId}/parents`,
      },
      {
        name: 'Add Student',
        route: `/school/${this.schoolId}/add_students/${this.$route.params.id}`,
      },
    ])

    fetchUser().then((user) => {
      this.user = user
    })

    this.$apollo.addSmartQuery('parent', {
      query: gql`
        query ParentQuery($id: ID!) {
          parent(id: $id) {
            id
            country {
              name
            }
            state {
              name
            }
            stateLga {
              name
            }
            familyName
            nameOfFather
            dobOfFather
            phoneOfFather
            emailOfFather
            nameOfMother
            dobOfMother
            phoneOfMother
            emailOfMother
            houseAddress
            users {
              id
              firebaseUserId
              email
            }
          }
        }
      `,
      variables: {
        id: this.$route.params.id,
      },
    })
  },
}
</script>

<style lang="scss" scoped>
.btn {
  width: 150px;
  margin-left: 8px;
}
</style>
